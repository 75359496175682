import React, { useEffect } from "react";
import Header from "./components/Header";
import FooterLinks from "./components/FooterLinks";
import DistortionEffect from "./components/DistortionEffect";
import "./css/App.scss";
// import Services from './pages/Services';
import News from "./pages/News";
import Home from "./pages/Home";
import Teams from "./pages/Teams";
// import Academy from "./pages/Academy";
import Jobs from "./pages/Jobs";
import About from "./pages/About";
import Tools from "./pages/Tools";
import Tnc from "./pages/Tnc";
import DigitalMarkettingTerms from "./pages/DigitalMarkettingTerms";
import VideoGallery from "./pages/VideoGallery";

import Example from "./pages/Example";
import Brochure from "./pages/Brochure";
import BrochureSlider from './pages/BrochureSlider';
import ContactUs from "./pages/ContactUs";
import FtpPage from "./pages/FtpPage";
import Impressium from "./pages/Impressium";
import SecurityPolicy from "./pages/SecurityPolicy";
import Gdpr from "./pages/Gdpr";
import PageNotFound from "./pages/PageNotFound";
import Archives from "./pages/Archives";
import Jonny from "./pages/Jonny";
import DevelopmentTerms from "./pages/DevelopmentTerms";
import HostingTerms from "./pages/HostingTerms";
import AmazonTerms from "./pages/AmazonTerms";
import XmasArt from "./pages/XmasArt";


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  useEffect(() => {
    /* ---navigation functions--- */
    var burgerBtn = document.getElementById("burgerBtn");
    var mobile = document.getElementById("site_container");
    var demo1 = document.getElementById("demo1");
    var demo2 = document.getElementById("demo2");
    var demo3 = document.getElementById("demo3");

    burgerBtn.addEventListener(
      "click",
      function () {
        mobile.classList.toggle("navigation");
      },
      false
    );

    demo1.addEventListener(
      "click",
      function () {
        demo1.classList.add("active");
        demo2.classList.remove("active");
        demo3.classList.remove("active");
        mobile.classList.add("demo1");
        mobile.classList.remove("demo2", "demo3", "navigation");
      },
      false
    );

    demo2.addEventListener(
      "click",
      function () {
        demo2.classList.add("active");
        demo1.classList.remove("active");
        demo3.classList.remove("active");
        mobile.classList.add("demo2");
        mobile.classList.remove("demo1", "demo3", "navigation");
      },
      false
    );

    demo3.addEventListener(
      "click",
      function () {
        demo3.classList.add("active");
        demo1.classList.remove("active");
        demo2.classList.remove("active");
        mobile.classList.add("demo3");
        mobile.classList.remove("demo1", "demo2", "navigation");
      },
      false
    );

    //let footerMenuObj = document.getElementsByClassName('footer_menu')[0];
  }, []);

  return (
    <div className="App">
      <Router>
        <div>
          <div id="site_container" className="site_container">
            <div id="burgerBtn"></div>
            <DistortionEffect />
            <div id="mobileBodyContent">
              <div className="homePageContainer">
                <Header />
                <div className="site_content">
                  <Switch>
                    {/* <Route path="/services">
                      <Services />
                    </Route> */}
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route path="/news">
                      <News />
                    </Route>
                    <Route path="/teams">
                      <Teams />
                    </Route>
                    {/* <Route path="/services">
                      <Services />
                    </Route> */}
                    <Route
                      path="/os"
                      component={() => {
                        window.location.href =
                          "https://www.sapnagroup.com/os/index.php";
                        return null;
                      }}
                    />
                     {/*<Route exact path="/academy">
                      <Academy />
                    </Route>*/}
                    <Route exact path="/jobs-page">
                      <Jobs />
                    </Route>
                    <Route exact path="/about">
                      <About />
                    </Route>
                    <Route exact path="/tools">
                      <Tools />
                    </Route>
                    <Route exact path="/tnc">
                      <Tnc />
                    </Route>
                    <Route exact path="/digital-marketing-terms">
                      <DigitalMarkettingTerms />
                    </Route>
                    <Route exact path="/videos">
                      <VideoGallery />
                    </Route>
                    <Route path="/videos/:id">
                      <VideoGallery />
                    </Route>
                    <Route exact path="/example">
                      <Example />
                    </Route>
                    <Route exact path="/portfolio">
                      <Brochure />
                    </Route>
                    <Route exact path="/contact">
                      <ContactUs />
                    </Route>
                    <Route exact path="/ftpsetup">
                      <FtpPage />
                    </Route>
                    <Route exact path="/impressum">
                      <Impressium />
                    </Route>
                    <Route exact path="/policy-and-cookies">
                      <Gdpr />
                    </Route>
                    <Route exact path="/security-policy">
                      <SecurityPolicy />
                    </Route>
                    <Route exact path="/historic">
                      <Archives />
                    </Route>
                    <Route exact path="/jonny">
                      <Jonny />
                    </Route>
                    <Route exact path="/development-terms">
                      <DevelopmentTerms />
                    </Route>
                    <Route exact path="/hosting-terms">
                      <HostingTerms />
                    </Route>
                    {/* <Route exact path="/brochure/about">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/bespoke-software-solutions">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/wordpress">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/server-management">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/apps">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/security">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/seo">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/content-creation">
                      <Brochure />
                    </Route>
                    <Route exact path="/brochure/other-services">
                      <Brochure />
                    </Route> */}
                    <Route path="/portfolio/:id">
                        <Brochure />
                    </Route>
                    <Route exact path="/amazon-terms">
                      <AmazonTerms/>
                    </Route>
                    <Route exact path="/XmasArt">
                      <XmasArt />
                    </Route>
                    <Route exact={true} path="*">
                      <PageNotFound />
                    </Route>
                  </Switch>
                </div>
                <FooterLinks />
              </div>
            </div>
          </div>
          {/* To be removed later */}
          <ul id="demoSelector">
            <li id="demo1" className="active">
              Menu slider effect 1
            </li>
            <li id="demo2">Menu slider effect 2</li>
            <li id="demo3">Menu slider effect 3</li>
          </ul>
        </div>
      </Router>
    </div>
  );
}

export default App;
