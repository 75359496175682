import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'; 
import {scroll_top,seo_tags} from '../js/helper.js';
import '../css/brochure.scss';
import '../css/xmasArt.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import Typography from '@material-ui/core/Typography';

// import "./styles.css";

// import required modules
import { FreeMode,  Thumbs, Navigation } from "swiper";

const useStyles = makeStyles((theme) => ({
	intro_para:{
    	paddingBottom:'30px'
  	}
}));

function XmasArt() {
    seo_tags({
      title: 'XMAS Art'
    });

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [slides, setSlides] = useState([]);

    return (
      <div className ="maxWrapper">             
        <h1>Christmas Art</h1>

        <Grid item xs={12} md={11} style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
            A few months before Christmas we invited the children of our team members to create Christmas-themed paintings. Our goal was to offer a fun and engaging activity for the kids and select the winning artwork as our official company Christmas card.
        </Grid>

        <Grid  item xs={12} md={11} style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto' }}>
            We were thrilled by the enthusiastic response from children of all ages! Each submission was unique and beautiful, making the task of choosing a winner incredibly challenging - we genuinely loved them all!
        </Grid>

        <Grid  item xs={12} md={11} style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto' }}>  
            Eventually we chose the submission from Kabir Shetty (5 years) as the motif for our Christmas cards 2024 and gave little prizes to him and all other participants of the competition at our company picnic.
        </Grid>

        <Grid  item xs={12} md={11} style={{ marginBottom: '50px', marginLeft: 'auto', marginRight: 'auto' }}>
            A heartfelt thank you to all the young artists who participated! We wish you and your families a Merry Christmas and a Happy New Year! 
        </Grid>

        <div className="brochureSliderwraper xmasArtSlider">
            <Swiper
                // ref={swiperRef}
                observeSlideChildren={true}
                style={{
                    "--swiper-navigation-color": "#000",
                    "--swiper-pagination-color": "#000",
                }}
        
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"             
            >

                
                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Kabir_05_amaya.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                    Drawing by: <strong>Kabir (1st Place)</strong>  <br></br> Age: <strong>5</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide>

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Swara_10_Swati.png'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Swara (2nd Place)</strong> <br></br> Age: <strong>10</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Reanna_11_Clarissa.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Reanna (3rd Place)</strong> <br></br> Age: <strong>11</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide>
                            
                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Aarushi_5_sushma_bandache.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />

                            <div className="art-by">
                                Drawing by: <strong>Aarushi </strong> <br></br> Age: <strong>5</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Anaisha_4_Sweta.png'
                                title="Xmas-card"
                                className="CoverPic"
                            />

                            <div className="art-by">
                                Drawing by: <strong>Anaisha </strong> <br></br> Age: <strong>4</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/avni_1.5_priyanka.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Avni </strong> <br></br> Age: <strong>1</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Darsh_8_Madura.png'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Darsh </strong> <br></br> Age: <strong>8</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Spruha_8_Madura.png'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Spruha </strong> <br></br> Age: <strong>8</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 
                

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/navya_3_neha.png'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Navya </strong> <br></br> Age: <strong>3</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide>

                <SwiperSlide >
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/RIANSHI_8YRS_PALLAVIBORKAR.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Rianshi </strong> <br></br> Age: <strong>8</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide>

                <SwiperSlide>
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Sanvi_1.8_Bhakti.jpeg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Sanvi </strong> <br></br> Age: <strong>1</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide>
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Saransh_4_Prasad.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Saransh </strong> <br></br> Age: <strong>4</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide>
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/xmas-tree-sunil.jpg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Shivansh </strong> <br></br> Age: <strong>7</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 

                <SwiperSlide>
                    <Grid container>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                        <Grid item xs={12} sm={12} md={10} align={'center'}>
                            <CardMedia
                                component="img"
                                alt="Xmas-card"
                                image='../xmas-art/Jivika_7_Chaitali.jpeg'
                                title="Xmas-card"
                                className="CoverPic"
                            />
                            <div className="art-by">
                                Drawing by: <strong>Jivika </strong> <br></br> Age: <strong>7</strong>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={1} align={'left'}></Grid>
                    </Grid>
                </SwiperSlide> 
            </Swiper>

            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={8}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper xmas-thumb-swiper"
            >   
            
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Kabir_05_amaya_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Swara_10_Swati_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Reanna_11_Clarissa_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>               
                <SwiperSlide className="thumbnails_wrap">  
                    <img src='../xmas-art/thumbnail/Aarushi_5_sushma_bandache_thumbnail.jpg' alt="XMAS Art"/>
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Anaisha_4_Sweta_thumbnail.jpg' alt="XMAS Art"/>
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/avni_1.5_priyanka_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Darsh_8_Madura_thumbnail.jpg' alt="XMAS Art"/>
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Spruha_8_Madura_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/navya_3_neha_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/RIANSHI_8YRS_PALLAVIBORKAR_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Sanvi_1.8_Bhakti_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Saransh_4_Prasad_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/xmas-tree-sunil_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
                <SwiperSlide className="thumbnails_wrap">
                    <img src='../xmas-art/thumbnail/Jivika_7_Chaitali_thumbnail.jpg' alt="XMAS Art" />
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
    )
}
export default XmasArt
